import axios from 'axios';

// Создаем экземпляр axios
const axiosInstance = axios.create({
  baseURL: 'https://api.k-system-k.ru',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Функция для получения токенов из локального хранилища
const getRefreshToken = () => localStorage.getItem('refresh_token');

// Добавляем интерцептор для обработки ошибок
axiosInstance.interceptors.response.use(
  (response) => response, // Успешный ответ
  async (error) => {
    if (error.response && error.response.status === 401) {
      
      if (window.location.pathname === '/login') {
        // Если пользователь уже на странице /login, ничего не делаем
        return Promise.reject(error);
      }

      try {
        // Пытаемся обновить токен
        const refreshToken = getRefreshToken();
        const refreshResponse = await axiosInstance.get('/auth/refresh', {headers: {Authorization: `Bearer ${refreshToken}`}});

        // Сохраняем новые токены
        localStorage.setItem('access_token', refreshResponse.data.accessToken);
        localStorage.setItem('refresh_token', refreshResponse.data.refreshToken);

        // Обновляем заголовок для текущего экземпляра axios
        axiosInstance.defaults.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;

        // Повторяем оригинальный запрос
        const originalRequest = error.config;
        originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        console.error('Ошибка обновления токена:', refreshError);
        // Вы можете перенаправить пользователя на страницу входа или обработать по-своему
        window.location.href = 'https://k-system-k.ru/login'
        window.location.reload()

        return Promise.reject(refreshError);
      }
    }

    // Если ошибка не 401, просто отклоняем
    return Promise.reject(error);
  }
);

export default axiosInstance;
