import React, { useState, useRef, useEffect } from 'react';
import styles from './MainPage.module.css'; // Импорт стилей
import Calendar from './../../components/Calendar/Calendar.jsx';
import filter_icon from '../../assets/MainPage/filter_icon.svg'
import Table from '../../components/Table/Table.jsx';
import useClickOutside from '../../components/UseClickOutside/UseClickOutside.jsx';
import JobTitle from '../../components/EmployeesPage/JobTitle/JobTitle.jsx';
import { getAppointmentsRequest, getStatusesByMonth, getUserProfile } from '../../api/requests.js';

function MainPage() {
  const [date, setDate] = useState(new Date());
  const [statuses, setStatuses] = useState([]);
  const [appointmentsdict, setAppointmentsdict] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('default');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [choosedJob, setChoosedJob] = useState([]);
  const [hourNorms, setHourNorms] = useState({
    night: {
      from: "00:00",
      to: "04:00"
    },
    morning: {
        from: "04:00",
        to: "12:00"
    },
    day: {
        from: "12:00",
        to: "18:00"
    },
    evening: {
        from: "18:00",
        to: "00:00"
    }
  });
  const [jobs, setJobs] = useState([
    { id: 0, name: 'Директор' },
    { id: 1, name: 'Работник' },
    { id: 2, name: 'Повар' },
  ]);

  let getAllData = async () => {
    let res = await getAppointmentsRequest();
    await getUserProfile()
    setJobs(res);
    let statuses = await getStatusesByMonth(`${date.getMonth() + 1}.${date.getFullYear()}`);
    setStatuses(statuses.appointments);
    setAppointmentsdict(statuses.appointmentsdict)

    const hourKeys = ['night', 'morning', 'day', 'evening']; // Ключи в нужном порядке

    // Преобразование массива в объект
    const updatedHourNorms = statuses.hournorms.reduce((acc, cur, index) => {
      if (hourKeys[index]) {
        acc[hourKeys[index]] = { from: cur.from, to: cur.to, count: +cur.norm };
      }
      return acc;
    }, {});

    setHourNorms(updatedHourNorms);
  }

  useEffect(() => {
    getAllData();
  }, [date]);

  let updateStatus = (jobTitle, workerId, day, newStatus, updatedPart, addNewPart) => {
    setStatuses((prevStatuses) =>
        prevStatuses.map((status) =>
            status.name === jobTitle
                ? {
                      ...status,
                      workers: status.workers.map((worker) =>
                          worker.id === workerId
                              ? {
                                    ...worker,
                                    dates: worker.dates.some((date) => date.day === day)
                                        ? worker.dates.map((date) =>
                                              date.day === day
                                                  ? {
                                                        ...date,
                                                        type: newStatus?.type || date.type,
                                                        parts: updatedPart
                                                            ? [...date.parts.map((part) => ({ ...part })), ...updatedPart]
                                                            : addNewPart !== undefined // Проверяем, был ли передан addNewPart
                                                            ? addNewPart // Устанавливаем новый массив, даже если он пустой
                                                            : [...date.parts],
                                                    }
                                                  : date
                                          )
                                        : [
                                              ...worker.dates,
                                              {
                                                  day,
                                                  type: newStatus?.type,
                                                  parts: addNewPart || [],
                                              },
                                          ],
                                }
                              : worker
                      ),
                  }
                : status
        )
    );
  };
  
  useEffect(() => {
    console.log(statuses)
  }, [statuses]);

  const addJob = (val) => {
    setChoosedJob((prevChoosedJob) => 
      prevChoosedJob.includes(val) 
        ? prevChoosedJob.filter((el) => el !== val) 
        : [...prevChoosedJob, val]
    );
  };

  const modalFilterRef = useRef();
  useClickOutside(modalFilterRef, () => {if(showFilterModal){setShowFilterModal(false)}});

  let setDateFunc = (val) => {
    setDate(val)
  }

  return (
    <div className={styles.main_container}>
      <div className={styles.main_header}>
        <Calendar setDateFunc={setDateFunc} dateFromMain={date} />
        <div ref={modalFilterRef} className={styles.main_header_filter} onClick={() => setShowFilterModal(true)}>
          <img src={filter_icon} alt=""/>
          {showFilterModal && <div className={styles.modal_filter_container}>
            <div className={styles.modal_filter_filt} onClick={() => setCurrentFilter('default')} style={{color: currentFilter === 'default' && 'rgb(99, 102, 241)'}}>
              По умолчанию
            </div>
            <hr style={{margin: "9px   0"}}/>
            <div className={styles.modal_filter_filt} onClick={() => setCurrentFilter('date')} style={{color: currentFilter === 'date' && 'rgb(99, 102, 241)'}}>
              По дате
            </div>
            <hr style={{margin: "9px 0"}}/>
            <div className={styles.modal_filter_status}>
              По статусу
              <div className={styles.modal_filter_statuses}>
                <div className={styles.modal_status_container} onClick={() => setCurrentFilter('D')} style={{color: currentFilter === 'D' && 'rgb(99, 102, 241)', border: currentFilter === 'D' && '1px solid rgb(99, 102, 241)'}}>Д</div>
                <div className={styles.modal_status_container} onClick={() => setCurrentFilter('U')} style={{color: currentFilter === 'U' && 'rgb(99, 102, 241)', border: currentFilter === 'U' && '1px solid rgb(99, 102, 241)'}}>У</div>
                <div className={styles.modal_status_container} onClick={() => setCurrentFilter('V')} style={{color: currentFilter === 'V' && 'rgb(99, 102, 241)', border: currentFilter === 'V' && '1px solid rgb(99, 102, 241)'}}>В</div>
                <div className={styles.modal_status_container} onClick={() => setCurrentFilter('O')} style={{color: currentFilter === 'O' && 'rgb(99, 102, 241)', border: currentFilter === 'O' && '1px solid rgb(99, 102, 241)'}}>О</div>
                <div className={styles.modal_status_container} onClick={() => setCurrentFilter('B')} style={{color: currentFilter === 'B' && 'rgb(99, 102, 241)', border: currentFilter === 'B' && '1px solid rgb(99, 102, 241)'}}>Б</div>
              </div>
            </div>
            <hr style={{margin: "9px 0"}}/>
            <div className={styles.modal_filter_status}>
              Должность
              <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob}  width={214}/>
            </div>
            <hr style={{margin: "9px 0"}}/>
          </div>} 
        </div>
      </div>
      <hr />
      <div className={styles.main_content}>
        {appointmentsdict.map((appointment, index) => (
          <Table
            key={appointment.name}
            JobTitleName={appointment.name}
            leftPart={statuses.find((el) => appointment.name === el.name)?.workers}
            widthPart="48px"
            canAddJobTitle={true}
            date={date}
            tableNumber={index}
            updateStatus={updateStatus} // Передаем функцию обновления
            appointmentsdict={appointment}
            hourNorms={hourNorms}
            jobs={jobs}
          />
        ))}
      </div>
    </div>
  );
}

export default MainPage;