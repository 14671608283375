import {useState, useRef, useEffect} from 'react';
import styles from './EmployeesPage.module.css'
import filter_header_icon from '../../assets/EmployeesPage/filter_header_icon.png'
import delete_icon from '../../assets/EmployeesPage/delete_icon.png'
import EmployeeChangeModal from '../../components/EmployeesPage/EmployeeChangeModal/EmployeeChangeModal';
import useClickOutside from './../../components/UseClickOutside/UseClickOutside';
import EmployeeAddModal from '../../components/EmployeesPage/EmployeeAddModal/EmployeeAddModal';
import {getAppointmentsRequest, getWorkersRequest, removeWorkerRequest} from "../../api/requests";
import JobTitle from '../../components/EmployeesPage/JobTitle/JobTitle';

function EmployeesPage() {
    const [workers, setWorkers] = useState([]);
    const [workerStatuses, setWorkerStatuses] = useState([]);
    const [allWorkerStatuses, setAllWorkerStatuses] = useState(0);
    const [currentWorker, setCurrentWorker] = useState();
    const [currentFilter, setCurrentFilter] = useState('Все')
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [employeeChangeModalShow, setEmployeeChangeShow] = useState(false)
    const [employeeAddModalShow, setEmployeeAddModalShow] = useState(false)
    const [choosedJob, setChoosedJob] = useState([]);
    const [jobs, setJobs] = useState([{id: 0, name: 'Директор'}, {id: 1, name: 'Работник'}, {id: 2, name: 'Повар'}]);

    useEffect(() => {
        let fetchData = async () => {
        let res = await getAppointmentsRequest();
        setJobs(res)
        }
        fetchData()
    }, []);
    
    const addJob = (val) => {
        setChoosedJob((prevChoosedJob) => 
        prevChoosedJob.includes(val) 
            ? prevChoosedJob.filter((el) => el !== val) 
            : [...prevChoosedJob, val]
        );
    };

    let resetWorkers = async () => {
        const workers = await getWorkersRequest(1)
        setWorkers(workers)
    }

    useEffect(() => {
        resetWorkers()
    }, []);

    useEffect(() => {
        const updatedStatuses = workers.map(worker => ({
          id: worker.id,
          bool: false // или любое начальное значение, которое вам нужно
        }));

        setWorkerStatuses(updatedStatuses);
    }, [workers]); 
    
    useEffect(() => {
        setAllWorkerStatuses(workerStatuses.filter(status => status.bool).length);
    }, [workerStatuses]);

    const modalFilterRef = useRef();
    useClickOutside(modalFilterRef, () => setShowFilterModal(false));

    let openEmployeeChange = (val) => {
        setEmployeeChangeShow(val)
    }

    let openEmployeeAdd = (val) => {
        setEmployeeAddModalShow(val)
    }

    // Функция для изменения значения bool у конкретного работника
    let toggleWorkerStatus = (id) => {
        setWorkerStatuses(prevStatuses =>
            prevStatuses.map(status =>
                status.id === id ? { ...status, bool: !status.bool } : status
            )
        );
    };

    let selectAllStatus = () => {
        if (allWorkerStatuses === 0) {
            setWorkerStatuses(prevStatuses =>
                prevStatuses.map(status => ({
                    ...status,
                    bool: true
                }))
            );
            setAllWorkerStatuses(workers.length); // Устанавливаем количество выбранных работников
        } else {
            setWorkerStatuses(prevStatuses =>
                prevStatuses.map(status => ({
                    ...status,
                    bool: false
                }))
            );
            setAllWorkerStatuses(0); // Сбрасываем счетчик
        }
    };

    let removeUser = (id) => {
        removeWorkerRequest(id)
        setWorkers(workers.filter((el) => el.id != id))
    }

    let deleteUsers = () => {
        workerStatuses.forEach((status) => {
            if(status.bool) {
                removeUser(status.id)
            }
        })
    }

    


    return (
        <div className={styles.employees_container}>
            <div className={styles.header_container}>
                <div className={styles.filter_container} ref={modalFilterRef}>
                    Фильтр:
                    <span className={styles.current_filter} onClick={() => setShowFilterModal(!showFilterModal)}>{currentFilter}<img src={filter_header_icon} className={styles.filter_container_img} alt=""/></span>
                    {showFilterModal && <div className={styles.filter_content}>
                        <div className={styles.filter_content_header} style={{color: currentFilter === 'Все' && 'rgb(99, 102, 241)'}} onClick={() => setCurrentFilter('Все')}>
                            Все
                        </div>
                        <hr/>
                        <div className={styles.filter_content_other}>
                            <span style={{color: currentFilter === 'Инд. условия' && 'rgb(99, 102, 241)'}} onClick={() => setCurrentFilter('Инд. условия')}>Инд. условия</span>
                            <span style={{color: currentFilter === 'Черновик' && 'rgb(99, 102, 241)'}} onClick={() => setCurrentFilter('Черновик')}>Черновик</span>
                            <span style={{color: currentFilter === 'Уволен' && 'rgb(99, 102, 241)'}} onClick={() => setCurrentFilter('Уволен')}>Уволен</span>
                        </div>
                        <hr/>
                        <div className={styles.jobtitle_choose}>
                            <div className={styles.jobtitle_choose_head}>
                                Должность
                            </div>
                            <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob}  width={214} height={39}/>
                        </div>
                        <hr style={{marginTop: '65px'}}/>
                        <div className={styles.jobtitle_choose}>
                            <div className={styles.jobtitle_choose_head}>
                                Сотрудники
                            </div>
                            <input type="text" className={styles.input_filter} placeholder='Введите ФИО'/>
                        </div>
                    </div>}
                </div>
                <button className={styles.add_employee} onClick={() => setEmployeeAddModalShow(true)}>
                    Добавить сотрудника
                </button>
            </div>
            <div className={styles.main_container}>
                <div className={styles.main_header}>
                    <div className={styles.main_header_select}>
                        <div className={styles.input_all} onClick={selectAllStatus} style={{border: allWorkerStatuses ? 'none' : '2px solid rgb(213, 213, 213)', background: allWorkerStatuses ? 'rgb(99, 102, 241)' : ''}}>{allWorkerStatuses}</div>
                        <span className={styles.input_all_header}>
                            {allWorkerStatuses} выбран
                        </span>
                    </div>
                    <div className={styles.main_header_delete} onClick={deleteUsers}>
                        <img src={delete_icon} alt=""/>
                        Удалить
                    </div>
                </div>

                <div className={styles.main_content_container}>
                    {workers && workers.map((el, index) => {
                        return (
                            <div className={styles.elem_container} key={el.email}
                                style={{borderTop: index === 0 ? '1px solid rgb(235, 239, 242)' : ''}}>
                                <div className={styles.elem_name}>
                                    <input
                                        type="checkbox"
                                        checked={workerStatuses.find((item) => item.id === el.id)?.bool || false}
                                        onChange={() => toggleWorkerStatus(el.id)} // используем onChange вместо valueChange
                                    />
                                    <img src="" alt=""/>
                                    {el.lastname} {el.firstname} {el.secondname}
                                </div>
                                <div className={styles.elem_email}>
                                    {el.email}
                                </div>
                                <div className={styles.elem_jobtitles}>
                                    {el.email}
                                </div>
                                <div className={styles.elem_statuses}>
                                    {el.email}
                                </div>
                                <div className={styles.elem_change}>
                                    <span onClick={() => {openEmployeeChange(true); setCurrentWorker(el)}}>
                                        Редактировать
                                    </span>
                                    <span onClick={() => removeUser(el.id)}>
                                        Удалить
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {employeeChangeModalShow ? <EmployeeChangeModal closeModal={openEmployeeChange} resetWorkers={resetWorkers} currentWorker={currentWorker}/> : <></>}
            {employeeAddModalShow ? <EmployeeAddModal closeModal={openEmployeeAdd} resetWorkers={resetWorkers}/> : <></>}
        </div>
    );
}

export default EmployeesPage;
