import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import error_icon from "../../../assets/LoginPage/error_icon.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";

function SignIn({styles}) {
  let [showPassword, setShowPassword] = useState(true)
  let [loginError, setLoginError] = useState(false)
  let [passwordError, setPasswordError] = useState(false)

  return (
    <>
        <div className={styles.login_title}>
            Вход
        </div>
        <div className={styles.input_container}>
            <span>Email</span>
            <input className={loginError && styles.input_error} type="text" placeholder="Введите ваш email" name="email"/>
            {loginError && <div className={styles.error_container}>
                <img src={error_icon} className={styles.error_icon} alt="" /> Данного пользователя не существует
            </div>}
        </div>
        <div className={styles.input_container}>
            <span>Пароль</span>
            <input className={passwordError && styles.input_error} type={showPassword ? 'password' : 'text'} placeholder="Введите пароль" name="password"/>
            <img className={styles.show_password_icon} onClick={() => {setShowPassword(!showPassword)}} style={{cursor: 'pointer'}} src={showPassword ? show_password : hide_password} alt="password" />
            {passwordError && <div className={styles.error_container}>
                <img src={error_icon} className={styles.error_icon} alt="" /> Неверный пароль
            </div>}
        </div>
        <button>
            Вход
        </button>
        <span style={{cursor: 'pointer'}}>
            <NavLink to="/login/recover">
                Забыли пароль ?
            </NavLink>
        </span>
    </>
  );
}

export default SignIn;
