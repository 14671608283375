import React, { useState, useEffect, useRef } from 'react';
import styles from './Calendar.module.css';
import { ReactComponent as ChangeArrowHeader } from '../../assets/Calendar/change_arrow_header.svg';
import change_arrow_content from '../../assets/Calendar/change_arrow_content.png';
import arrow_change_down from '../../assets/Calendar/arrow_change_down.png';
import useClickOutside from '../UseClickOutside/UseClickOutside';

function Calendar({ setDateFunc, isInput, dateFromMain}) {
  const calendarRef = useRef();
  const monthModalRef = useRef();
  const yearModalRef = useRef();

  const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [daysArray, setDaysArray] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showMonthModal, setShowMonthModal] = useState(false);
  const [showYearModal, setShowYearModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [months] = useState(["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]);

  const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    startYear = startYear || 1980;  
    const years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }   
    return years.reverse();
  }

  const [years] = useState(getYears(2000));

  useEffect(() => {
    displayCalendar();
    if(dateFromMain && dateFromMain == date) {
      console.log(dateFromMain + '         ' + date)
      setDateFunc(date)
    } else if(!dateFromMain) {
      setDateFunc(date)
    }
  }, [date]);


  const displayCalendar = () => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    let firstDayIndex = firstDay.getDay();
    const numberOfDays = lastDay.getDate();
    firstDayIndex = firstDayIndex === 0 ? 6 : firstDayIndex - 1;

    const daysTempArray = [];

    for (let x = 0; x < firstDayIndex; x++) {
      daysTempArray.push("");
    }

    for (let i = 1; i <= numberOfDays; i++) {
      const currentDate = new Date(year, month, i);
      daysTempArray.push(currentDate);
    }

    setDaysArray(daysTempArray);
  }

  const handlePrevious = () => {
    const newMonth = month === 0 ? 11 : month - 1;
    const newYear = month === 0 ? year - 1 : year;
    setMonth(newMonth);
    setYear(newYear);
    const newDate = new Date(newYear, newMonth, 1);
    setDate(newDate);
    setDateFunc(newDate);
  };

  const handleNext = () => {
    const newMonth = month === 11 ? 0 : month + 1;
    const newYear = month === 11 ? year + 1 : year;
    setMonth(newMonth);
    setYear(newYear);
    const newDate = new Date(newYear, newMonth, 1);
    setDate(newDate);
    setDateFunc(newDate);
  };

  const setMonthFunc = (letMonth) => {
    setMonth(letMonth);
    const newDate = new Date(year, letMonth, 1);
    setDate(newDate);
    setDateFunc(newDate);
  };

  const setYearFunc = (letYear) => {
    setYear(letYear);
    const newDate = new Date(letYear, month, 1);
    setDate(newDate);
    setDateFunc(newDate);
  };

  const handleDayClick = (day) => {
    if (day) {
      setSelectedDate(day.toDateString()); // Устанавливаем выбранную дату для отображения
      setDateFunc(day); // Передаем выбранную дату в родительский компонент
    }
  };

  const formatDate = (date) => {
    let dateStr = date.toLocaleString("ru", { month: "long", year: "numeric" }).replace('г.', '').trim();
    const words = dateStr.split(' ');
    const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return (
      <>
        <span>{firstWord}</span> <span>{words[1]}</span>
      </>
    );
  }

  const formatDateModal = (date) => {
    let dateStr = date.toLocaleString("ru", { month: "long", year: "numeric" }).replace('г.', '').trim();
    const words = dateStr.split(' ');
    const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return (
      <>
        <span onClick={() => setShowMonthModal(!showMonthModal)}>
          {firstWord}
          <img src={arrow_change_down} alt="" />
        </span> 
        <span onClick={() => setShowYearModal(!showYearModal)}>
          {words[1]}
          <img src={arrow_change_down} alt="" />
        </span>
      </>
    );
  }

  const formatDateInput = (date) => {
    if (!date) return ''; // Если дата не задана, вернуть пустую строку
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0'); // Получаем день и добавляем ноль впереди
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Получаем месяц (0-11), добавляем 1 и добавляем ноль впереди
    const year = d.getFullYear(); // Получаем полный год
  
    return `${day}.${month}.${year}`; // Возвращаем отформатированную дату
  };
  

  useClickOutside(monthModalRef, () => setShowMonthModal(false));
  useClickOutside(yearModalRef, () => setShowYearModal(false));
  useClickOutside(calendarRef, () => setShowCalendar(false));

  return (
    <>
      <input
        disabled
        style={{ display: "none" }} 
        type="month"
        value={`${year}-${String(month + 1).padStart(2, "0")}`}
        onChange={(e) => {
          const [newYear, newMonth] = e.target.value.split("-");
          const newDate = new Date(newYear, newMonth - 1, 1);
          setDate(newDate);
          setDateFunc(newDate);
        }}
      />

      <div className={styles.date_calendar_container} ref={calendarRef} onClick={() => setShowCalendar(true)}>
        {isInput ? 
        <input
          className={styles.date_container_input}
          type="text"
          value={formatDateInput(date)} // Используйте date непосредственно
          placeholder="__.__.____"
        />
        :
        <div className={styles.date_container}>
          {formatDate(date)} 
          <div className={styles.header_arrow_container}>
            <ChangeArrowHeader className={styles.header_arrow_icon} onClick={handlePrevious}/>
            <ChangeArrowHeader className={styles.header_arrow_icon} onClick={handleNext}/>
          </div>
        </div>}
        {showMonthModal && <div ref={monthModalRef} className={styles.month_container}>
            {months.map((el, index) => (
              <span key={el} style={{ color: index === date.getMonth() ? '#6366F1' : '' }} onClick={() => setMonthFunc(index)}>{el}</span>
            ))}
          </div>
        }
        {showYearModal && <div ref={yearModalRef} className={styles.year_container}>
            {years.map((el) => (
              <span key={el} style={{ color: el === date.getFullYear() ? '#6366F1' : '' }} onClick={() => setYearFunc(el)}>{el}</span>
            ))}
          </div>
        }
        <div style={{ display: showCalendar ? 'block' : 'none' }} className={styles.calendar_content}>
          <div className={styles.container_calendar}>
            <div className={styles.calendar}>
              <div className={styles.header_calendar}>
                <pre className={styles.left} onClick={handlePrevious}><img src={change_arrow_content} alt="" /></pre>
                <div className={styles.header_display}>
                  <div className={styles.display}>{formatDateModal(date)}</div>
                </div>
                <pre className={styles.right} onClick={handleNext} style={{ transform: 'rotate(180deg)' }}><img src={change_arrow_content} alt="" /></pre>
              </div>

              <div className={styles.week}>
                <div>Пн</div>
                <div>Вт</div>
                <div>Ср</div>
                <div>Чт</div>
                <div>Пт</div>
                <div>Сб</div>
                <div>Вс</div>
              </div>

              <div className={styles.days}>
                {daysArray.map((day, index) => (
                  <div
                    key={index}
                    className={`day ${day && day.toDateString() === new Date().toDateString() ? "current-date" : ""}`}
                    onClick={() => handleDayClick(day)}
                  >
                    {day ? day.getDate() : ""}
                  </div>
                ))}
              </div>
            </div>
                
            {/* <div className={styles.display_selected}>
              <p className={styles.selected}>Выбранная дата: {selectedDate}</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Calendar;
