import styles from './ChangeDayStatus.module.css'; // Импорт стилей
import closeIcon from '../../../assets/Table/close_addempoyee_icon.svg';
import { useState, useEffect, useRef } from 'react';
import Checkbox from './../../Checkbox/Checkbox';
import { createDayStatusWorker, updateDayStatusWorker } from '../../../api/requests';
import useClickOutside from '../../UseClickOutside/UseClickOutside';


function ChangeDayStatus({ seeModal, setChangeDayStatus, currentDayStatus, handleStatusUpdate, appointmentsdict, workersWithUpdatedDays, hourNorms }) {
  const [sick, setSick] = useState(currentDayStatus?.type === 'SCK');
  const [vacation, setVacation] = useState(currentDayStatus?.type === 'HLD');
  const [daysStatus, setDaysStatus] = useState([]);

  let convertToTimeObject = (decimalHours) => {
    const hours = Math.floor(decimalHours); // Целая часть числа — часы
    const minutes = decimalHours % 1 === 0.5 ? 30 : 0; // Если дробная часть 0.5, то минуты 30; иначе 0
  
    return { hours, minutes };
  }

  useEffect(() => {
    if(currentDayStatus?.type === 'HLD') {
      setVacation(true)
    } else {
      setVacation(false)
    }
    if(currentDayStatus?.type === 'SCK') {
      setSick(true)
    } else {
      setSick(false)
    }
    if(currentDayStatus?.type === 'multiple' || currentDayStatus?.type === 'simple') {
      let data = [
        {
          name: 'День',
          active: false,
          start: '08:00',
          end: '12:00',
          duration: { hours: 4, minutes: 0 },
        },
        {
          name: 'Утро',
          active: false,
          start: '12:00',
          end: '14:00',
          duration: { hours: 2, minutes: 0 },
        },
        {
          name: 'Вечер',
          active: false,
          start: '16:00',
          end: '18:00',
          duration: { hours: 2, minutes: 0 },
        },
        {
          name: 'Ночь',
          active: false,
          start: '18:00',
          end: '20:00',
          duration: { hours: 2, minutes: 0 },
        },
      ];
      
      const nameMapping = {
        'День': 'day',
        'Утро': 'morning',
        'Вечер': 'evening',
        'Ночь': 'night'
      };
      
      const updatedData = data.map((item) => {
        // Найти совпадение в currentDayStatus.parts, используя маппинг
        const match = currentDayStatus.parts.find(part => {
          const translatedName = nameMapping[item.name]; // Переводим русское имя в английское
          return translatedName === part.type; // Сравниваем с type из currentDayStatus.parts
        });
      
        if (match) {
          // Рассчитать длительность, если доступны start и end
          const duration = calculateDuration(match.from || item.start, match.to || item.end);
      
          return {
            ...item,
            active: true, // Устанавливаем active в true
            start: match.from || item.start, // Обновляем start, если есть значение
            end: match.to || item.end, // Обновляем end, если есть значение
            duration: duration || '' // Устанавливаем рассчитанную продолжительность
          };
        }
      
        return item; // Если совпадений нет, возвращаем оригинальный объект
      });
      
      
      // Обновить состояние
      setDaysStatus(updatedData);
    } else {
      setDaysStatus([
        {
          name: 'День',
          active: false,
          start: hourNorms.day.from || '08:00',
          end: hourNorms.day.to || '12:00',
          duration: convertToTimeObject(hourNorms.day.count),
        },
        {
          name: 'Утро',
          active: false,
          start: hourNorms.morning?.from || '12:00',
          end: hourNorms.morning?.to || '14:00',
          duration: convertToTimeObject(hourNorms.morning.count),
        },
        {
          name: 'Вечер',
          active: false,
          start: hourNorms.evening?.from || '16:00',
          end: hourNorms.evening?.to || '18:00',
          duration: convertToTimeObject(hourNorms.evening.count),
        },
        {
          name: 'Ночь',
          active: false,
          start: hourNorms.night?.from || '18:00',
          end: hourNorms.night?.to || '20:00',
          duration: convertToTimeObject(hourNorms.night.count),
        },
      ]);
    }
  }, [currentDayStatus]);

  let container = useRef()

  // Обнуляем активные статусы, если sick или vacation равно true
  useEffect(() => {
    if (sick || vacation) {
      setDaysStatus((prevStatus) =>
        prevStatus.map((status) => ({
          ...status,
          active: false,
        }))
      );
    }
  }, [sick, vacation]);

  // Обработчик изменения статуса
  const toggleStatus = (index) => {
    if (!sick && !vacation) {
      setDaysStatus((prevStatus) =>
        prevStatus.map((status, i) =>
          i === index ? { ...status, active: !status.active } : status
        )
      );
    }
  };

  // Функция для обновления времени и пересчета продолжительности
  const updateTime = (index, field, value) => {
    setDaysStatus((prevStatus) =>
      prevStatus.map((status, i) => {
        if (i === index) {
          const updatedStatus = { ...status, [field]: value };
          if (updatedStatus.start && updatedStatus.end) {
            const duration = calculateDuration(updatedStatus.start, updatedStatus.end);
            updatedStatus.duration = duration;
          }
          return updatedStatus;
        }
        return status;
      })
    );
  };

  // Вычисление продолжительности (в формате { hours, minutes })
  const calculateDuration = (start, end) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);

    let totalStart = startHours * 60 + startMinutes;
    let totalEnd = endHours * 60 + endMinutes;

    // Учитываем переход через полночь
    if (totalEnd < totalStart) {
      totalEnd += 24 * 60;
    }

    const totalDuration = totalEnd - totalStart;
    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    return { hours, minutes };
  };

  const nameToTypeMapping = {
    утро: "morning",
    день: "day",
    вечер: "evening",
    ночь: "night",
  };

  const save = async () => {
    let parts = [];
    let type = null;
  
    // Если выбран "Болел" или "Отпуск"
    if (sick) {
      parts.push({ type: "SCK" });
      type = "SCK";
    } else if (vacation) {
      parts.push({ type: "HLD" });
      type = "HLD";
    } else {
      // Если выбраны временные интервалы
      parts = daysStatus
        .filter((status) => status.active)
        .map((status) => {
          const partType = nameToTypeMapping[status.name.toLowerCase()] || null;
          if (!type && partType) type = partType; // Устанавливаем первый активный тип
          return {
            type: partType,
            from: status.start,
            to: status.end,
            count: status.duration.hours + status.duration.minutes / 60,
          };
        });
    }
  
    const data = {
      day: currentDayStatus.workerId.index + 1,
      month: currentDayStatus.date.getMonth() + 1,
      year: currentDayStatus.date.getFullYear(),
      workerid: currentDayStatus.workerId.workerId,
      appointmentid: appointmentsdict.id,
      parts: parts,
    };
  
    let typeForUpdate = type === 'SCK' ? {type: 'SCK'} : type === 'HLD' ? {type: 'HLD'} : {type: 'multiple'} || {type: 'simple'}
    
    handleStatusUpdate(
      currentDayStatus.workerId.workerId,
      currentDayStatus.workerId.index + 1,
      typeForUpdate, // Подставляем SCK, HLD или первый активный nameToTypeMapping
      null,
      parts
    );
    setChangeDayStatus(false)

    let dates = workersWithUpdatedDays.find((el) => el.id == currentDayStatus.workerId.workerId)?.dates

    let res;
    if(dates?.some((day) => day.day == currentDayStatus.workerId.index + 1)) {
       res = await updateDayStatusWorker(data,`${String(currentDayStatus.workerId.index + 1).padStart(2, '0')}-${currentDayStatus.date.getMonth() + 1}-${currentDayStatus.date.getFullYear()}`);
    } else {
      res = await createDayStatusWorker(data,`${String(currentDayStatus.workerId.index + 1).padStart(2, '0')}-${currentDayStatus.date.getMonth() + 1}-${currentDayStatus.date.getFullYear()}`);
    }
  };
  
    
  useClickOutside(container, setChangeDayStatus);

  return (
    <>
      {seeModal && (
        <div className={styles.main_container}>
          <div className={styles.container} ref={container}>
            <div className={styles.header}>
              «Актуальная дата»
              <img src={closeIcon} alt="" onClick={setChangeDayStatus} />
            </div>
            <hr className={styles.container_hr} style={{ margin: '5px 0 20px 0' }} />
            <div className={`${styles.statuses_container} ${sick || vacation ? styles.statuses_container_inactive : ''}`}>
              {daysStatus.map((el, index) => (
                <div key={index} className={styles.status}>
                  <div
                    onClick={() => toggleStatus(index)} // Переключение статуса
                    className={`${styles.status_container} ${
                      el.active ? styles.status_active : styles.status_disable
                    }`}
                  >
                    {el.name}
                  </div>
                  {el.active && (
                    <div className={styles.time_container}>
                      <div className={styles.first_time_container}>
                        <div>
                          <span>С</span>{' '}
                          <input
                            type="time"
                            value={el.start}
                            onChange={(e) => updateTime(index, 'start', e.target.value)}
                          />
                        </div>
                        <div>
                          <span>до</span>{' '}
                          <input
                            type="time"
                            value={el.end}
                            onChange={(e) => updateTime(index, 'end', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className={styles.second_time_container}>
                        {el.duration && (
                          <>
                            <span>ч.</span> {el.duration.hours} <span>м.</span> {el.duration.minutes}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.additional_statuses_container}>
              <div className={styles.additional_status_container}>
                <Checkbox value={vacation} valueChange={() => setVacation(!vacation)} /> Отпуск
              </div>
              <div className={styles.additional_status_container}>
                <Checkbox value={sick} valueChange={() => setSick(!sick)} /> Болел
              </div>
            </div>
            <hr style={{ margin: '0' }} />
            <div className={styles.bottom_container}>
              <button className={styles.first_button} onClick={setChangeDayStatus}>
                Отмена
              </button>
              <button className={styles.second_button} onClick={save}>
                Сохранить
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChangeDayStatus;
