import styles from './ProfilePage.module.css';
import TimeRate from './../../TimeRate/TimeRate';
import DayTable from './../DayTable/DayTable';
import { useState } from 'react';
import Calendar from '../../Calendar/Calendar';
import { createWorkerRequest } from '../../../api/requests';

function ProfilePage() {
  const [profileInfo, setProfileInfo] = useState({
    telephone: '',
    email: '',
    birthday: '',
    passportNumber: '',
    oneCNumber: '',
    city: '',
    street: '',
    houseNumber: '',
    houseCorpus: '',
    departmentNumber: ''  // Исправлено поле для номера подразделения
  });

  // Функция для изменения значений в profileInfo
  const handleProfileChange = (field, value) => {
    setProfileInfo((prevInfo) => ({
      ...prevInfo,
      [field]: value
    }));
  };

  const changeProfile = async() => {
    
    // let data = await createWorkerRequest(profileInfo)
  };

  return (
    <div className={styles.container}>
      <div className={styles.info_container}>
        <div className={styles.info_container_title}>
          <div className={styles.title_info_container}>
            <div className={styles.title_info_img}>
              <img src="" alt="" />
            </div>
          </div>
          <div className={styles.title_role_container} />
        </div>
        <div className={styles.info_inputs_container}>
          <div className={styles.info_input_container}>
            <div className={styles.info_input}>
              <span>Телефон</span>
              <input 
                type="text" 
                value={profileInfo.telephone} 
                placeholder='8 (___) ___ - __ - __' 
                onChange={(e) => handleProfileChange('telephone', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span>Email</span>
              <input 
                type="text" 
                value={profileInfo.email} 
                placeholder='Email' 
                onChange={(e) => handleProfileChange('email', e.target.value)} 
              />
            </div>
          </div>
          <div className={styles.info_input_container}>
            <div className={styles.info_input}>
              <span>Дата рождения</span>
              <Calendar setDateFunc={(date) => handleProfileChange('birthday', date)} isInput={true} />
            </div>
            <div className={styles.info_input}>
              <span>Номер паспорта</span>
              <input 
                type="text" 
                value={profileInfo.passportNumber} 
                onChange={(e) => handleProfileChange('passportNumber', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span>Номер 1С</span>
              <input 
                type="text" 
                value={profileInfo.oneCNumber} 
                onChange={(e) => handleProfileChange('oneCNumber', e.target.value)} 
              />
            </div>
          </div>
          <div className={styles.info_input_container}>
            <div className={styles.info_input}>
              <span>Адрес</span>
              <input 
                type="text" 
                value={profileInfo.city} 
                placeholder='Город' 
                onChange={(e) => handleProfileChange('city', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span></span>
              <input 
                type="text" 
                value={profileInfo.street} 
                placeholder='Улица' 
                onChange={(e) => handleProfileChange('street', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span></span>
              <input 
                type="text" 
                value={profileInfo.houseNumber} 
                placeholder='Номер дома' 
                onChange={(e) => handleProfileChange('houseNumber', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span></span>
              <input 
                type="text" 
                value={profileInfo.houseCorpus} 
                placeholder='Корпус' 
                onChange={(e) => handleProfileChange('houseCorpus', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span>Номер подразделения</span>
              <input 
                type="text" 
                value={profileInfo.departmentNumber} 
                placeholder='Введите номер' 
                onChange={(e) => handleProfileChange('departmentNumber', e.target.value)} 
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.daytable_container}>
        <DayTable />
      </div>
      <div className={styles.rate_container}>
        <div className={styles.rate_container_title}>Ставки</div>
        <div className={styles.rate_individual_container}>
          <div className={styles.rate_individual_variant}>
            <span>Повар</span>
            <span>Шеф повар</span>
          </div>
          <div className={styles.rate_individual}>
            <input type="checkbox" />
            Индивидуальные условия
          </div>
        </div>
        <TimeRate />
      </div> */}
      <div className={styles.buttons_container}>
        <button>Удалить аккаунт</button>
        <button onClick={changeProfile}>Сохранить</button>
      </div>
    </div>
  );
}

export default ProfilePage;
