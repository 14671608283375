import { useEffect } from 'react';
import styles from './DayTable.module.css';

function DayTable({ time }) {
  const hours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
  const sticks = Array(96).fill(null); 

  const convertMinutesToPixels = (startTime, endTime) => {
    const startHour = parseInt(startTime.split(':')[0]);
    const endHour = parseInt(endTime.split(':')[0]);

    const startElement = document.querySelectorAll('#hours_container li')[startHour];
    const startElementNext = document.querySelectorAll('#hours_container li')[startHour + 1];
    const endElement = document.querySelectorAll('#hours_container li')[endHour];
    const endElementNext = document.querySelectorAll('#hours_container li')[endHour + 1];

    if (!startElement || !endElement || !startElementNext || !endElementNext) return 0;

    // Получаем размеры элементов
    const startRect = startElement.getBoundingClientRect();
    const startRectNext = startElementNext.getBoundingClientRect();
    const endRect = endElement.getBoundingClientRect();
    const endRectNext = endElementNext.getBoundingClientRect();

    // Расстояние между началом следующего элемента и концом текущего элемента
    const distanceBetweenStartAndNext = startRectNext.left - startRect.right;
    const distanceBetweenEndAndNext = endRectNext.left - endRect.right;

    let firstPixels = distanceBetweenStartAndNext / 60 * parseInt(startTime.split(':')[1])
    let secondPixels = distanceBetweenEndAndNext / 60 * parseInt(endTime.split(':')[1])

    if(firstPixels !== 0) {
      firstPixels += 10
    }

    if(secondPixels !== 0) {
      secondPixels += 10
    }

    return [firstPixels, secondPixels]
  };


  const convertTimeToPercentage = (timeString) => {
    const [hour] = timeString.split(':').map(Number);
    const element = document.querySelectorAll('#hours_container li')[hour];
    const container = document.querySelector('#hours_container');

    if (!element || !container) return { start: 0, end: 0 };

    let elementRect = element.getBoundingClientRect();
    let containerRect = container.getBoundingClientRect();

    let start = elementRect.left - containerRect.left
    let end = containerRect.right - elementRect.right
    
    return {
      start: start,
      end: end
    };
  };

  const splitTimeInterval = (start, end) => {
    const [startHour] = start.split(':').map(Number);
    const [endHour] = end.split(':').map(Number);
  
    if (startHour > endHour) {
      // Если время пересекает границу дня
      return [
        { start, end: '23:59' }, // Первая часть: до конца дня
        { start: '00:01', end } // Вторая часть: начало следующего дня
      ];
    }
    return [{ start, end }]; // Обычный интервал
  };

  useEffect(() => {
    const firstPartIntervals = []; // Для начальных временных интервалов
    const secondPartIntervals = []; // Для конечных временных интервалов
  
    // Разделяем данные
    Object.keys(time).forEach((key, index) => {
      const intervals = splitTimeInterval(time[key].first, time[key].second);
      if (intervals.length === 2) {
        firstPartIntervals.push({
          start: convertTimeToPercentage(intervals[0].start),
          end: convertTimeToPercentage(intervals[0].end),
          minutesPixels: convertMinutesToPixels(intervals[0].start, intervals[0].end),
        });
        secondPartIntervals.push({
          start: convertTimeToPercentage(intervals[1].start),
          end: convertTimeToPercentage(intervals[1].end),
          minutesPixels: convertMinutesToPixels(intervals[1].start, intervals[1].end),
          index: index
        });
      } else {
        firstPartIntervals.push({
          start: convertTimeToPercentage(intervals[0].start),
          end: convertTimeToPercentage(intervals[0].end),
          minutesPixels: convertMinutesToPixels(intervals[0].start, intervals[0].end),
        });
      }
    });
    
    // Обновляем контейнеры для первой части
    firstPartIntervals.forEach((el, index) => {
      let indexMile = 0
      if(index === 0) {
        indexMile = 3
      } else if(index === 1) {
        indexMile = 1
      } else if(index === 2) {
        indexMile = 0
      } else if(index === 3) {
        indexMile = 2
      }
      const lineContainer = document.querySelector(`#active_line_container_${indexMile}`);
      if (lineContainer) {
        lineContainer.style.left = `${el.start.start === 0 ? el.start.start + el.minutesPixels[0] : el.start.start + el.minutesPixels[0] + 6}px`;

        const rightValue = el.end.end === 0 ? '0px' : `${el.end.end - el.minutesPixels[1] + 5}px`;
        lineContainer.style.right = rightValue;
      }
    });
  
    document.querySelector(`#active_line_container_4`).style.right = 0
    document.querySelector(`#active_line_container_5`).style.right = 0
    document.querySelector(`#active_line_container_6`).style.right = 0
    document.querySelector(`#active_line_container_7`).style.right = 0


    // Обновляем контейнеры для второй части
    secondPartIntervals.forEach((el) => {
      let indexMile = 0
      if(el.index === 0) {
        indexMile = 3
      } else if(el.index === 1) {
        indexMile = 1
      } else if(el.index === 2) {
        indexMile = 0
      } else if(el.index === 3) {
        indexMile = 2
      }

      const lineContainer = document.querySelector(`#active_line_container_${indexMile + 4}`); // Сдвигаем индекс для второй части
      if (lineContainer) {
        lineContainer.style.left = `0px`;
        lineContainer.style.right = `${el.end.end === 0 ? 0 : el.end.end - el.minutesPixels[1]}px`;
      }
    });
  }, [time]);

  return (
    <div className={styles.table_container}>
      <ul id='hours_container'>
        {hours.map((el, index) => (
          <li key={index}>{el}</li>
        ))}
      </ul>
      <div className={styles.sticks_container}>
        {sticks.map((_, index) => (
          <div key={index} className={styles.stick}></div>
        ))}
      </div>
      <div className={styles.empty_lines_container} id='empty_lines_container'>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_0'></div>
          <div className={styles.active_line_container} id='active_line_container_4'></div>
        </div>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_1' style={{background: '#FF9500'}}></div>
          <div className={styles.active_line_container} id='active_line_container_5' style={{background: '#FF9500'}}></div>
        </div>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_2' style={{background: '#BFBFBF'}}></div>
          <div className={styles.active_line_container} id='active_line_container_6' style={{background: '#BFBFBF'}}></div>
        </div>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_3' style={{background: '#3E3E3E'}}></div>
          <div className={styles.active_line_container} id='active_line_container_7' style={{background: '#3E3E3E'}}></div>
        </div>
      </div>
      <div className={styles.colors_container}>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#DFE709' }}></div>
          День
        </div>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#FF9500' }}></div>
          Утро
        </div>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#BFBFBF' }}></div>
          Вечер
        </div>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#3E3E3E' }}></div>
          Ночь
        </div>
      </div>
    </div>
  );
}

export default DayTable;
