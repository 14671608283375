import { useState, useEffect } from 'react';
import DayTable from './../DayTable/DayTable';
import styles from './ParametersPage.module.css'
import { getTime, resetTime } from '../../../api/requests';

function ParametersPage() {
  const [error, setError] = useState('')
  const [time, setTime] = useState({
    first: { first: '08:30', second: '10:30' },
    second: { first: '10:30', second: '12:30' },
    third: { first: '12:30', second: '14:30' },
    fourth: { first: '14:30', second: '16:30' },
  });

  const order = [0, 1, 2, 3];

// Функция для преобразования данных в нужный формат
const transformShifts = (shiftData) => {
  const keys = ["fourth", "first", "second", "third"];

  return shiftData.reduce((acc, shift, index) => {
    const newIndex = order[index];  // Новый индекс
    const key = keys[newIndex];      // Используем его для соответствующего ключа

    acc[key] = { first: shift.from, second: shift.to };  // Заполняем объект
    return acc;
  }, {});
}

  useEffect(() => {
    const fetchData = async () => {
      let res = await getTime();
      if(res.length !== 0) {
        setTime(transformShifts(res))
      }
    };

    fetchData()
  }, []);

  const handleTimeChange = (period, key, value) => {
    setTime(prevTime => ({
      ...prevTime,
      [period]: {
        ...prevTime[period],
        [key]: value
      }
    }));
  };

  function calculateTimeDifference(start, end, forData = false) {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
  
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
  
    let differenceInMinutes = endTotalMinutes - startTotalMinutes;
  
    if (differenceInMinutes < 0) {
      differenceInMinutes += 24 * 60;
    }
  
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = (differenceInMinutes % 60).toString().padStart(2, '0');
  
    let res = forData ? +`${hours}.${minutes}` : `${hours} ч. ${minutes} мин.`;
    return res;
  }

  let saveTime = () => {
    setError('')
    let data = {
      nightnorm: {
          from: time.fourth.first,
          to: time.fourth.second,
          norm: calculateTimeDifference(time.fourth.first, time.fourth.second, true)
      },
      morningnorm: {
          from: time.first.first,
          to: time.first.second,
          norm: calculateTimeDifference(time.first.first, time.first.second, true)
      },
      daynorm: {
          from: time.second.first,
          to: time.second.second,
          norm: calculateTimeDifference(time.second.first, time.second.second, true)
      },
      eveningnorm: {
          from: time.third.first,
          to: time.third.second,
          norm: calculateTimeDifference(time.third.first, time.third.second, true)
      }
    }

    const totalNorm = data.nightnorm.norm + data.morningnorm.norm + data.daynorm.norm + data.eveningnorm.norm;

    if (totalNorm > 24) {
      setError('Общее количество часов больше 24')
    } else {
      resetTime(data)
    }
  }

  return (
    <div className={styles.container}>
      <DayTable time={time} />

      <div className={styles.times_container}>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>Утро</div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (утро)</span>
            <input type="text" value={calculateTimeDifference(time.first.first, time.first.second)}/>
          </div>
          <div className={styles.time_container_input}>
            <span>Период (утро)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.first.first}
                onChange={(e) => handleTimeChange('first', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.first.second}
                onChange={(e) => handleTimeChange('first', 'second', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>День</div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (день)</span>
            <input type="text" value={calculateTimeDifference(time.second.first, time.second.second)}/>
          </div>
          <div className={styles.time_container_input}>
            <span>Период (день)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.second.first}
                onChange={(e) => handleTimeChange('second', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.second.second}
                onChange={(e) => handleTimeChange('second', 'second', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>Вечер</div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (вечер)</span>
            <input type="text" value={calculateTimeDifference(time.third.first, time.third.second)}/>
          </div>
          <div className={styles.time_container_input}>
            <span>Период (вечер)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.third.first}
                onChange={(e) => handleTimeChange('third', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.third.second}
                onChange={(e) => handleTimeChange('third', 'second', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>Ночь</div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (ночь)</span>
            <input type="text" value={calculateTimeDifference(time.fourth.first, time.fourth.second)}/>
          </div>
          <div className={styles.time_container_input}>
            <span>Период (ночь)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.fourth.first}
                onChange={(e) => handleTimeChange('fourth', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.fourth.second}
                onChange={(e) => handleTimeChange('fourth', 'second', e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.button_container}>
        {error && <div className={styles.error}>{error}</div>}
        <button onClick={saveTime}>Сохранить</button>
      </div>
    </div>
  );
}

export default ParametersPage;
