import {Navigate, redirect, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import main_img from '../../assets/LoginPage/main_page.png'
import SignIn from '../../components/LoginPage/SignIn/SignIn';
import styles from './LoginPage.module.css'
import {authRequest} from '../../api/requests.js'
import {useState} from "react";
import RecoverPassword from '../../components/LoginPage/RecoverPassword/RecoverPassword.jsx';
import NewPassword from '../../components/LoginPage/NewPassword/NewPassword.jsx';

function LoginPage() {
    const location = useLocation(); // получаем текущий путь
    const navigate = useNavigate()
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    if(isAuthorized()) {
        return <Navigate replace to="/"/>
    }

    async function handleSubmit(event) {
        event.preventDefault()

        const response = await authRequest({
            'companyid': 3,
            'email': event.target.email.value,
            'password': event.target.password.value
        })
        if(response.accessToken && response.refreshToken) {
            localStorage.setItem('access_token', response.accessToken)
            localStorage.setItem('refresh_token', response.refreshToken)
            navigate('/')
        }
    }

    function isAuthorized() {
        return !!(localStorage.getItem('access_token') && localStorage.getItem('refresh_token'));
    }

    return (
        <div className={styles.login_container}>
            <img src={main_img} alt="main_img"/>
            <form onSubmit={handleSubmit} className={styles.login_content}>
                {location.pathname == '/login' && <SignIn styles={styles}/>}
                {location.pathname == '/login/recover' && <RecoverPassword styles={styles}/>}
                {location.pathname == '/login/newpassword' && <NewPassword styles={styles}/>}
            </form>
        </div>
    );
}

export default LoginPage;
