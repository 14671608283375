import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import error_icon from "../../../assets/LoginPage/error_icon.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";

function NewPassword({ styles }) {
    const [showFirstPassword, setShowFirstPassword] = useState(true);
    const [showSecondPassword, setShowSecondPassword] = useState(true);
    const [secondPasswordError, setSecondPasswordError] = useState(false);

    return (
        <>
            <div className={styles.login_title}>
                Восстановление пароля
            </div>
            {/* Первое поле для нового пароля */}
            <div className={styles.input_container}>
                <span>Пароль</span>
                <input
                    type={showFirstPassword ? "password" : "text"}
                    placeholder="Введите пароль"
                    name="password"
                />
                <img className={styles.show_password_icon} onClick={() => setShowFirstPassword(!showFirstPassword)} style={{ cursor: "pointer" }} src={showFirstPassword ? show_password : hide_password} alt="toggle password visibility"/>
            </div>

            {/* Второе поле для подтверждения пароля */}
            <div className={styles.input_container}>
                <span>Подтвердите пароль</span>
                <input
                    className={secondPasswordError ? styles.input_error : ""}
                    type={showSecondPassword ? "password" : "text"}
                    placeholder="Введите пароль"
                    name="confirmPassword"
                />
                <img className={styles.show_password_icon} onClick={() => setShowSecondPassword(!showSecondPassword)} style={{ cursor: "pointer" }} src={showSecondPassword ? show_password : hide_password} alt="toggle password visibility"/>
                {secondPasswordError && (
                    <div className={styles.error_container}>
                        <img src={error_icon} className={styles.error_icon} alt="error" /> Пароли не совпадают
                    </div>
                )}
            </div>

            <button>
                Сохранить
            </button>
            <span style={{ cursor: "pointer" }}>
                <NavLink to="/login">
                    Войти в личный кабинет
                </NavLink>
            </span>
        </>
    );
}

export default NewPassword;
